import { addElementToHead } from '@zyro-inc/site-modules/utils/addDomElements';
import { EcommerceProduct } from '@zyro-inc/site-modules/types/index';
import { ELEMENT_DATA_ATTRIBUTE } from '@zyro-inc/site-modules/constants';
import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';
import { formatPrice } from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';
import {
	getLowestPriceVariant,
	getHighestPriceVariant,
} from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import { removeHtmlTags } from '@zyro-inc/site-modules/utils/removeHtmlTags';

type ProductJsonLdHeadScript = {
	type: string;
	tagName: string;
	properties: {
		[ELEMENT_DATA_ATTRIBUTE]: string;
		type: string;
	};
	children: {
		type: string;
		value: string;
	}[];
};

type ProductSchema = {
	'@context': string;
	'@type': string;
	name: string;
	image: string[];
	description: string;
	offers?: {
		'@type'?: string;
		url?: string;
		priceCurrency?: string;
		price?: string;
		lowPrice?: string;
		highPrice?: string;
		availability?: string;
	};
};

export const generateProductSchema = ({
	product,
	pageSlug,
}: { product: EcommerceProduct, pageSlug: string}): ProductSchema => {
	const domainNameWithoutWww = getDomainWithoutWWWPrefix(window.location.hostname);
	const { variants } = product;

	const productHasVariants = variants.length > 1;

	const productSchema: ProductSchema = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		name: product.title,
		image: product.images.map((image) => image.url),
		description: product.description ? removeHtmlTags(product.description) : '',
		offers: {},
	};

	if (productHasVariants) {
		const lowestPriceVariant = getLowestPriceVariant(product);
		const lowestPrice = lowestPriceVariant.prices[0];
		const lowestPriceAmount = formatPrice({
			amount: lowestPrice.sale_amount || lowestPrice.amount,
			currency: lowestPrice.currency,
			isPriceDisplayedWithCurrency: false,
		});

		const highestPriceVariant = getHighestPriceVariant(product);
		const highestPrice = highestPriceVariant.prices[0];
		const highestPriceAmount = formatPrice({
			amount: highestPrice.sale_amount || highestPrice.amount,
			currency: highestPrice.currency,
			isPriceDisplayedWithCurrency: false,
		});

		productSchema.offers = {
			'@type': 'AggregateOffer',
			url: `https://${domainNameWithoutWww}/${pageSlug}`,
			priceCurrency: highestPrice.currency.code,
			lowPrice: lowestPriceAmount,
			highPrice: highestPriceAmount,
		};
	} else {
		const variant = variants[0];
		const price = variant.prices[0];

		const priceAmount = formatPrice({
			amount: price.sale_amount || price.amount,
			currency: price.currency,
			isPriceDisplayedWithCurrency: false,
		});

		productSchema.offers = {
			'@type': 'Offer',
			url: `https://${domainNameWithoutWww}/${pageSlug}`,
			priceCurrency: price.currency.code,
			price: priceAmount,
			availability: 'https://schema.org/InStock',
		};
	}

	return productSchema;
};

export const addProductJsonLd = ({
	product,
	pageSlug,
}: {
	product: EcommerceProduct;
	pageSlug: string;
}): void => {
	const productJsonLdHeadScript: ProductJsonLdHeadScript = {
		type: 'element',
		tagName: 'script',
		properties: {
			[ELEMENT_DATA_ATTRIBUTE]: `jsonld-product-${product.id}`,
			type: 'application/ld+json',
		},
		children: [],
	};

	productJsonLdHeadScript.children = [
		{
			type: 'text',
			value: JSON.stringify(generateProductSchema({
				product,
				pageSlug,
			})),
		},
	];

	addElementToHead(productJsonLdHeadScript);
};
