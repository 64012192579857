import { EcommerceProduct } from '@zyro-inc/site-modules/types';

export const getLowestPriceVariant = (product: EcommerceProduct) => product.variants.reduce((acc, curr) => {
	const accPrice = acc.prices[0]?.sale_amount || acc.prices[0]?.amount;
	const currPrice = curr.prices[0]?.sale_amount || curr.prices[0]?.amount;

	return accPrice < currPrice ? acc : curr;
});

export const getHighestPriceVariant = (product: EcommerceProduct) => product.variants.reduce((acc, curr) => {
	const accPrice = acc.prices[0]?.sale_amount || acc.prices[0]?.amount;
	const currPrice = curr.prices[0]?.sale_amount || curr.prices[0]?.amount;

	return accPrice > currPrice ? acc : curr;
});
