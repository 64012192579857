export const getWebsiteCategoryUrl = (categoryId: string, blockId: string) => {
	const newUrl = new URL(window.location.href);

	if (categoryId) {
		newUrl.searchParams.set('category', categoryId);
	} else {
		newUrl.searchParams.delete('category');
	}

	newUrl.hash = `#${blockId}`;

	return newUrl.href;
};
